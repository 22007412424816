import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

const IndexPage = () => (
  <Layout>
    <SEO title="Sarine and Jesse's Wedding" />
    <h1>SAJ 2020</h1>
    <p>Enjoy the preview!</p>
    <div style={{ maxWidth: `1000px`, marginBottom: `1.45rem` }}>
      <Video url='https://d26914svare7a0.cloudfront.net/SAJ_2020_preview.mp4' />
    </div>
    <Link to="/full-video/">Full Video</Link>
  </Layout>
)

export default IndexPage
